<template>
  <v-form v-model="valid">
    <p class="text-center font-gotham mt-6 mb-0">
      {{
        $vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.specialization.title"
        )
      }}
    </p>
    <v-container class="pa-10">
      <v-row justify="center">
        <v-col cols="12" md="4">
          <p class="font-gotham text-center">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.specialization.main_treatment_method"
              )
            }}
          </p>
          <v-radio-group v-model="main_treatment_method">
            <v-radio
              v-for="(item, i) in main_treatment_method_Items"
              :key="i + item.title"
              v-bind:label="item.title"
              v-bind:value="item.title"
            >
            </v-radio>
          </v-radio-group>
          <v-divider class="mt-2 mb-5"></v-divider>
          <p class="font-gotham text-center">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.specialization.additionalEducation"
              )
            }}
          </p>
          <v-checkbox
            v-for="(item, i) in additionalEducation"
            :key="item.id"
            v-model="additionalEducation[i].selected"
            :true-value="true"
            :false-value="false"
            :input-value="item.title"
            :value="item.selected"
            :label="item.title"
            hide-details="true"
            class="mt-0 pt-0 mb-0 pb-0"
          >
          </v-checkbox>
          <v-checkbox
            v-model="anotherSelectTextArea"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.psychologistDashboard.specialization.other'
              )
            "
            value=""
          >
          </v-checkbox>
          <p class="text-center" v-if="anotherSelectTextArea">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.specialization.additionalEducation"
              )
            }}
          </p>
          <v-textarea
            v-if="anotherSelectTextArea"
            v-model="additional_knowledge_other"
            class="mx-2"
            rows="1"
            prepend-icon="mdi-comment"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="1">
          <v-divider v-if="!window.isVisibleDivider" vertical></v-divider>
          <v-divider v-if="window.isVisibleDivider"></v-divider>
        </v-col>
        <v-col cols="12" md="7" class="mt-2">
          <v-row justify="center">
            <p class="text-center">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.specialization.subtitle"
                )
              }}
            </p>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <p class="font-gotham mb-0">
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.specialization.life_event_problems"
                  )
                }}
              </p>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in lifeEventsProblems1"
                    :key="item.id"
                    v-model="lifeEventsProblems1[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1 pr-0"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in lifeEventsProblems2"
                    :key="item.id"
                    v-model="lifeEventsProblems2[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1 pr-0"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <p class="font-gotham pb-0 mb-0">
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.specialization.mental_health_condition_problems"
                  )
                }}
              </p>

              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in mentalHealthConditionProblems3"
                    :key="item.id"
                    v-model="mentalHealthConditionProblems3[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in mentalHealthConditionProblems4"
                    :key="item.id"
                    v-model="mentalHealthConditionProblems4[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <p class="font-gotham pb-0 mb-0">
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.specialization.relationship_problems"
                  )
                }}
              </p>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in relationshipProblems5"
                    :key="item.id"
                    v-model="relationshipProblems5[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in relationshipProblems6"
                    :key="item.id"
                    v-model="relationshipProblems6[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <p class="font-gotham pb-0 mb-0">
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.specialization.career_problems"
                  )
                }}
              </p>

              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in careerProblems7"
                    :key="item.id"
                    v-model="careerProblems7[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-for="(item, i) in careerProblems8"
                    :key="item.id"
                    v-model="careerProblems8[i].selected"
                    :true-value="true"
                    :false-value="false"
                    :input-value="item.title"
                    :value="item.selected"
                    :label="item.title"
                    hide-details="true"
                    class="mt-0 pt-0 mb-1"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <v-btn @click="submitEdits">
          {{
            $vuetify.lang.t(
              "$vuetify.main.psychologistDashboard.specialization.save_changes"
            )
          }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  name: "Specialization",
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.specialization"),
    };
  },
  data() {
    return {
      valid: false,
      window: {
        isVisibleDivider: false,
        width: 0,
      },

      selected: [],

      anotherSelectTextArea: false,
      additional_knowledge_other: "",
      radioGroup: 1,
      column: null,
      enabled: false,
      main_treatment_method_Items: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.art_therapy"
          ),
          id: "art_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.client_centered_psychotherapy"
          ),
          id: "client_centered_psychotherapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.cognitive_behavioral_therapy"
          ),
          id: "cognitive_behavioral_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.body_therapy"
          ),
          id: "body_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.psychoanalysis"
          ),
          id: "psychoanalysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.jungian_analysis"
          ),
          id: "jungian_analysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.transactional_analysis"
          ),
          id: "transactional_analysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.family_therapy"
          ),
          id: "family_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.gestalt_therapy"
          ),
          id: "gestalt_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.psychodrama"
          ),
          id: "psychodrama",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.analytical_psychology"
          ),
          id: "analytical_psychology",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.symboldrama"
          ),
          id: "symboldrama",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.existential_analysis"
          ),
          id: "existential_analysis",
        },
      ],

      additionalEducation: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.addictions"
          ),
          selected: false,
          id: "addictions",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.eating_disorders"
          ),
          selected: false,
          id: "eating_disorders",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.sexology"
          ),
          selected: false,
          id: "sexology",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.extreme_situations"
          ),
          selected: false,
          id: "extreme_situations",
        },
      ],
      lifeEventsProblems1: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.relocate_emigration"
          ),
          selected: false,
          id: "relocate_emigration",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.financial_changes"
          ),
          selected: false,
          id: "financial_changes",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.betrayal"
          ),
          selected: false,
          id: "betrayal",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.illness"
          ),
          selected: false,
          id: "illness",
        },
      ],
      lifeEventsProblems2: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.pregnancy"
          ),
          selected: false,
          id: "pregnancy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.divorce"
          ),
          selected: false,
          id: "divorce",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.violence"
          ),
          selected: false,
          id: "violence",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.loss_of_a_loved_one"
          ),
          selected: false,
          id: "loss_of_a_loved_one",
        },
      ],
      mentalHealthConditionProblems3: [
        {
          title: "ПТСР",
          selected: false,
          id: "ptsd",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.stress"
          ),
          selected: false,
          id: "stress",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.panic_attacks"
          ),
          selected: false,
          id: "panic_attacks",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.low_self_esteem"
          ),
          selected: false,
          id: "low_self_esteem",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.sleep_problems"
          ),
          selected: false,
          id: "sleep_problems",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.emotional_addiction"
          ),
          selected: false,
          id: "emotional_addiction",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.hypochondria"
          ),
          selected: false,
          id: "hypochondria",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.alcohol_drugs_addiction"
          ),
          selected: false,
          id: "alcohol_drugs_addiction",
        },
      ],
      mentalHealthConditionProblems4: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.anxiety"
          ),
          selected: false,
          id: "anxiety",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.breakdown"
          ),
          selected: false,
          id: "breakdown",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.mood_swings"
          ),
          selected: false,
          id: "mood_swings",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.irritability"
          ),
          selected: false,
          id: "irritability",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.feelings_of_loneliness"
          ),
          selected: false,
          id: "feelings_of_loneliness",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.social_network_addiction"
          ),
          selected: false,
          id: "social_network_addiction",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.eating_disorders"
          ),
          selected: false,
          id: "eating_disorders",
        },
      ],
      relationshipProblems5: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.with_partner"
          ),
          selected: false,
          id: "with_partner",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.with_children"
          ),
          selected: false,
          id: "with_children",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.sexual"
          ),
          selected: false,
          id: "sexual",
        },
      ],
      relationshipProblems6: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.with_parents"
          ),
          selected: false,
          id: "with_parents",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.common"
          ),
          selected: false,
          id: "common",
        },
      ],
      careerProblems7: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.stress_at_work"
          ),
          selected: false,
          id: "stress_at_work",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.procrastination"
          ),
          selected: false,
          id: "procrastination",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.no_goal"
          ),
          selected: false,
          id: "no_goal",
        },
      ],
      careerProblems8: [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.lack_of_motivation"
          ),
          selected: false,
          id: "lack_of_motivation",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.cannot_find_myself"
          ),
          selected: false,
          id: "cannot_find_myself",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.specialization.job_loss_change"
          ),
          selected: false,
          id: "job_loss_change",
        },
      ],

      main_treatment_method: "",
      main_treatment_method_id: "",
      additional_knowledge: [],
      answers: {
        career_problems: [],
        life_event_problems: [],
        mental_health_condition_problems: [],
        relationship_problems: [],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  created() {
    this.main_treatment_method_Items.forEach((method) => {
      if (method.id === this.user.main_treatment_method_value) {
        this.main_treatment_method = method.title;
      }
    });
    this.additional_knowledge = this.user.additional_knowledge;
    this.answers = this.user.answers;
    this.additional_knowledge_other = this.user.additional_knowledge_other;
    if (this.user.additional_knowledge_other) {
      this.anotherSelectTextArea = true;
    }
  },
  beforeMount() {
    this.$store.dispatch("user/getProfile");
    this.setAnswersValue(
      this.additional_knowledge,
      this.additionalEducation,
      []
    );

    this.setAnswersValue(
      this.answers.life_event_problems,
      this.lifeEventsProblems1,
      this.lifeEventsProblems2
    );
    this.setAnswersValue(
      this.answers.mental_health_condition_problems,
      this.mentalHealthConditionProblems3,
      this.mentalHealthConditionProblems4
    );
    this.setAnswersValue(
      this.answers.relationship_problems,
      this.relationshipProblems5,
      this.relationshipProblems6
    );
    this.setAnswersValue(
      this.answers.career_problems,
      this.careerProblems7,
      this.careerProblems8
    );
  },

  watch: {
    selected() {},
    anotherSelectTextArea() {
      if (!this.anotherSelectTextArea) {
        this.additional_knowledge_other = "";
      }
    },
    user: function () {
      this.answers = this.user.answers;
      this.additional_knowledge = this.user.additional_knowledge;
      this.main_treatment_method_Items.forEach((method) => {
        if (method.id === this.user.main_treatment_method_value) {
          this.main_treatment_method = method.title;
        }
      });
      this.additional_knowledge_other = this.user.additional_knowledge_other;
      if (this.user.additional_knowledge_other) {
        this.anotherSelectTextArea = true;
      }
      this.setAnswersValue(
        this.additional_knowledge,
        this.additionalEducation,
        []
      );

      this.setAnswersValue(
        this.answers.life_event_problems,
        this.lifeEventsProblems1,
        this.lifeEventsProblems2
      );
      this.setAnswersValue(
        this.answers.mental_health_condition_problems,
        this.mentalHealthConditionProblems3,
        this.mentalHealthConditionProblems4
      );
      this.setAnswersValue(
        this.answers.relationship_problems,
        this.relationshipProblems5,
        this.relationshipProblems6
      );
      this.setAnswersValue(
        this.answers.career_problems,
        this.careerProblems7,
        this.careerProblems8
      );
    },
    main_treatment_method: function () {
      this.main_treatment_method_Items.forEach((method) => {
        if (method.title === this.main_treatment_method) {
          this.main_treatment_method_id = method.id;
        }
      });
    },
  },
  methods: {
    setAnswersValue(actualValues, prevValueGroup1, prevValueGroup2 = []) {
      actualValues?.forEach((actualVal) => {
        prevValueGroup1.forEach((prevVal) => {
          if (actualVal.includes(prevVal.title)) {
            prevVal.selected = true;
          }
        });
      });
      actualValues?.forEach((actualVal) => {
        prevValueGroup2.forEach((prevVal) => {
          if (actualVal.includes(prevVal.title)) {
            prevVal.selected = true;
          }
        });
      });
    },
    preparePayloadData() {
      this.additional_knowledge = [];
      this.answers = {
        career_problems: [],
        life_event_problems: [],
        mental_health_condition_problems: [],
        relationship_problems: [],
      };
      const addActualAnswersVal = (
        currentAnswersGroup1,
        currentAnswersGroup2 = [],
        answersForPayload
      ) => {
        currentAnswersGroup1.forEach((answer) => {
          if (answer.selected) {
            answersForPayload.push(answer.id);
          }
        });
        currentAnswersGroup2.forEach((answer) => {
          if (answer.selected) {
            answersForPayload.push(answer.id);
          }
        });
      };
      addActualAnswersVal(
        this.additionalEducation,
        [],
        this.additional_knowledge
      );
      addActualAnswersVal(
        this.lifeEventsProblems1,
        this.lifeEventsProblems2,
        this.answers.life_event_problems
      );
      addActualAnswersVal(
        this.mentalHealthConditionProblems3,
        this.mentalHealthConditionProblems4,
        this.answers.mental_health_condition_problems
      );
      addActualAnswersVal(
        this.relationshipProblems5,
        this.relationshipProblems6,
        this.answers.relationship_problems
      );
      addActualAnswersVal(
        this.careerProblems7,
        this.careerProblems8,
        this.answers.career_problems
      );
    },
    submitEdits() {
      this.preparePayloadData();

      let payload = {
        profile: {
          main_treatment_method: this.main_treatment_method_id,
          additional_knowledge: this.additional_knowledge,
        },
        general_questions: {
          career_problems: this.answers.career_problems,
          life_event_problems: this.answers.life_event_problems,
          mental_health_condition_problems:
            this.answers.mental_health_condition_problems,
          relationship_problems: this.answers.relationship_problems,
        },
      };
      if (this.anotherSelectTextArea) {
        payload.profile.additional_knowledge_other =
          this.additional_knowledge_other;
      }
      this.$store.dispatch("user/updateProfile", payload).then((response) => {
        Vue.swal({
          text: response.data.message,
          icon: "success",
          toast: true,
          modal: false,
          timer: 3000,
          timerProgressBar: true,
          position: "top-right",
          showConfirmButton: false,
        });
      });
    },
  },
};
</script>

<style scoped></style>
